// mui V5 upgrading done. Please do not import any component from material-ui v4

import styled, { css } from 'styled-components'
import {
  Button,
  CardContent,
  Divider,
  Card,
  Checkbox,
  Dialog,
  Box,
} from '@mui/material'
import LoaderButton from 'src/components/inputs/LoaderButton'

export const StyledCard = styled(Card)<{ $noBoxShadow?: boolean }>(
  ({ $noBoxShadow = false }) => css`
    width: 100%;
    max-width: 738px;
    z-index: 1;
    ${$noBoxShadow &&
    css`
      box-shadow: none;
    `}
  `
)

export const BaseCardContent = styled(CardContent)`
  max-height: 560px;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(5)}px;
`

export const StyledCardContent = styled(BaseCardContent)(
  ({ theme }) => css`
    max-height: 100%;
    padding-right: ${theme.spacing(2)}px;
  `
)

export const InstructorAgreementCardContent = styled(CardContent)<{
  $removePaddingXs?: boolean
  $removeMobileHeight?: boolean
}>(
  ({ theme, $removePaddingXs = false, $removeMobileHeight = false }) => css`
    ${theme.breakpoints.up('sm')} {
      max-height: 763px;
      padding: ${({ theme }) => theme.spacing(5)}px;
    }
    ${theme.breakpoints.down('xs')} {
      ${!$removeMobileHeight &&
      css`
        height: 100vh;
      `}
      padding: ${({ theme }) =>
        $removePaddingXs ? theme.spacing(0, 2) : theme.spacing(3, 2)};
    }
    display: flex;
    flex-direction: column;
  `
)

export const StyledDivider = styled(Divider)(
  ({ theme }) => css`
    margin: ${theme.spacing(4, 0, 5)};
  `
)

export const AgreementContentContainer = styled(Box)(
  ({ theme }) => css`
    height: calc(100vh - 380px);
    overflow-y: auto;
    ${theme.breakpoints.down('xs')} {
      padding-top: ${theme.spacing(3)}px;
    }
    padding-top: ${theme.spacing(4)}px;
    box-shadow: inset 0px -12px 12px -12px ${theme.palette.grey[500]};
    & .darkBlue {
      color: #294a8b;
    }
  `
)

export const AgreementContent = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(3)}px;
  `
)

export const StyledButton = styled(Button)(
  ({ theme }) => css`
    padding: ${theme.spacing(1.75, 2)};
  `
)

// anti-pattern component, style is showing disabled but clickable,
// the requirement is from ticket LCS-5059
export const DisabledStyleButClickableActionButton = styled(LoaderButton)<{
  $isDisabledStyle?: boolean
}>(
  ({ theme, $isDisabledStyle }) => css`
    padding: ${theme.spacing(1.75, 2)};
    min-width: 80px;
    ${theme.breakpoints.down('xs')} {
      width: 100%;
    }
    &&.MuiButton-containedPrimary {
      color: ${$isDisabledStyle
        ? theme.palette.disabled.main
        : theme.palette.brand.white};
      opacity: ${$isDisabledStyle ? 0.25 : 1};
    }
  `
)

export const ActionButton = styled(LoaderButton)<{ $fullWidth?: boolean }>(
  ({ theme, $fullWidth }) => css`
    padding: ${theme.spacing(1.75, 2)};
    min-width: 80px;

    ${$fullWidth &&
    css`
      width: 100%;
    `}
  `
)

export const StyledCheckbox = styled(Checkbox)`
  svg {
    width: 27px;
    height: 27px;
  }
`

export const StyledAgreeLabelContainer = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacing(3)}px;
  `
)

export const ScrollContainer = styled.div(
  ({ theme }) => css`
    flex: 1;
    overflow-y: auto;
    padding-right: ${theme.spacing(3)}px;
  `
)

export const HelpContainer = styled.div(
  ({ theme }) => css`
    & .MuiTypography-root {
      font-size: 12px;
      line-height: 19px;
    }
    ${theme.breakpoints.down('xs')} {
      order: 2;
    }
  `
)

export const WarningContainer = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacing(3)}px;
    & .MuiTypography-root {
      font-size: 12px;
      line-height: 19px;
    }
  `
)

export const AgreementDialog = styled(Dialog)`
  .MuiPaper-root {
    width: 100%;
    max-width: 738px;
  }
`
export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 160px;
`
